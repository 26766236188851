.center {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 700px;
  padding: 32px;
  width: 100vw;
}
@media screen and (max-width: 700px) {
  .center {
      max-width: 100%;
  }
}

.listDetails {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.listDetails details {
  width: 100%;
}
.listDetails details[open] > summary {
  color: var(--color-button);
}
.listDetails details summary {
  font-weight: 500;
  color: var(--color-grey-dark);
  cursor: pointer;
  padding: 16px;
  width: 100%;
}
.listDetails details:not(:last-child) > summary {
  border-bottom: 1px solid rgb(233, 233, 233);
}
.listDetails details > div {
  background-color: #f7f7f7;
  border-radius: 10px;
  color: var(--color-grey-dark);
  display: flex;
  flex-direction: column;
  font-size: .9rem;
  line-height: 170%;
  gap: 16px;
  padding: 20px;
  margin-top: 10px;
}
.listDetails details > div ul li {
  list-style-position: inside;
}
.listDetails mark {
  background-color: #728ebb5b;
  padding: 2px;
  border-radius: 2px;
}
