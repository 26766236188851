@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-outlined {
        @apply py-3.5 px-6
  text-primary 
    text-center
    bg-transparent 
    hover:opacity-75 
    rounded-2xl outline 
  outline-primary outline-2
    w-full
    text-base
    font-serif
    font-bold;
    }

    .tag {
        @apply bg-tag-active
      mt-3
      font-serif
      inline-block
      text-center
      font-bold
      rounded-full
      min-w-20
      px-2;
    }

    .tag-error {
        @apply bg-tag-inactive
      mt-3
      font-serif
      inline-block
      text-center
      font-bold
      rounded-full
      min-w-20
      px-2;
    }
}
