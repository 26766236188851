.content_center {
    align-items: center;
    display: flex;
    font-size: 18px;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    margin: 0 auto;
    max-width: 440px;
    padding: 30px;
    width: 100%;
}