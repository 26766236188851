.content-center {
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
}

img {
    height: auto;
}

.top-header {
    background-color: var(--color_primary);
    background-repeat: no-repeat;
    background-position: 0 14%;
    background-size: contain;
}
.top-header nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    width: 100%;
}
.top-header nav img {
    height: auto;
    max-width: 70%;
    width: 100%;
}
.top-header .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.top-header .content .text {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.top-header .content header {
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.top-header .content header h1 {
    font-size: 45px;
    font-weight: 400;
    letter-spacing: -5%;
    line-height: 120%;
}
.top-header .content header p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -3%;
    line-height: 160%;
}
.top-header .content .image-mockup {
    display: flex;
    justify-content: flex-end;
}
.top-header .content .image-mockup img {
    max-height: 800px;
    margin-bottom: -140px;
    width: auto;
}

.btn-apps {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
}
.btn-apps ul {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
.btn-apps ul li {
    list-style: none;
}
.btn-apps ul li img {
    height: auto;
    max-width: 150px;
}
.mobile {
    display: none;
}

.controls {
    background-color: var(--color-grey);
    padding: 200px 0 100px 0;
}
.controls button {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr auto;
    width: initial;
}
.controls button svg {
    max-width: 20px;
    width: 100%;
}
.controls header h2 {
    color: var(--color_primary);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -6%;
    line-height: 120%;
}
.controls .content-center {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.list-controls {
    display: grid;
    grid-template-columns: repeat(4, minmax(10px, 1fr));
    gap: 20px;
}
.list-controls li {
    border-radius: 30px;
    box-shadow: 0 10px 40px 0 hsla(0, 0%, 0%, .10);
    color: var(--color-grey-dark);
    font-size: 14px;
    font-weight: 400;
    list-style-type: none;
    padding: 26px 24px;
}
.list-controls li h3 {
    font-size: 20px;
    font-weight: 800;
    color: var(--color_primary);
    margin-bottom: 20px;
    line-height: 120%;
    letter-spacing: -4%;
}

.solution {
    background-color: var(--color_primary);
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 100px 0;
}
.solution .str {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(312px, 1fr));
    gap: 100px;
}
.solution .str .img img {
    width: 100%;
}
.solution .solutions .list-solutions li {
    align-items: flex-start;
    color: #FFFFFF;
    display: grid;
    font-size: 20px;
    gap: 20px;
    grid-template-columns: 20px 1fr;
    list-style-type: none;
}
.solution .solutions .list-solutions li:not(:first-child) {
    margin-top: 40px;
}
.solution .solutions .list-solutions li svg {
    max-width: 20px;
}

.steps-solution {
    padding: 100px 0;
}
.steps-solution header h2 {
    color: var(--color_primary);
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -5%;
    line-height: 120%;
}
.steps-solution .btn-apps ul {
    flex-direction: column;
}
.steps-solution .steps {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 100px;
    margin-top: 60px;
}
.steps-solution .steps .list-steps ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 40px;
}
.steps-solution .steps .list-steps ul li {
    list-style-type: none;
}
.steps-solution .steps .list-steps ul li h3 {
    font-size: 18px;
    font-weight: 500;
    color: var(--color_primary);
    margin-bottom: 20px;
    line-height: 120%;
    letter-spacing: -4%;
}
.steps-solution .steps .list-steps ul li p {
    color: var(--color-grey-dark);
}
.footer-home {
    background-color: #F5F5F5;
    padding: 50px 0;
}
.footer-home h2 {
    color: var(--color_primary);
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -5%;
    line-height: 120%;
    margin-bottom: 20px;
}
.footer-home .boxs,
.footer-home .boxs a {
    color: var(--color_primary);
}
.footer-home .boxs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 60px;
}
.footer-home .list-questions {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.footer-home .list-questions li {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 20px;
}

.copy {
    background-color: #F5F5F5;
    color: var(--color-grey-dark);
    padding-bottom: 40px;
}
.copy .list-copy {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}
.copy .list-copy li {
    list-style-type: none;
}
.copy .list-copy li p {
    font-size: 14px;
}
.copy .list-copy li p a {
    color: #555555;
}

@media screen and (max-width: 1100px) {
    .content-center {
        max-width: 100%;
        padding: 0 30px;
    }
}
@media screen and (max-width: 800px) {
    .desk {
        display: none;
    }
    .mobile {
        display: flex;
    }
    .top-header {
        background-position: center center;
        background-size: auto;
    }
    .top-header .content {
        grid-template-columns: 1fr;
    }
    .top-header .content header h1 {
        font-size: 34px;
    }
    .top-header .content header p {
        font-size: 18px;
    }
    .top-header .content .image-mockup {
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }
    .top-header .content .image-mockup img {
        width: 100%;
        height: 100%;
        max-height: initial;
        margin-bottom: 0;
    }
    .complement-mobile {
        display: flex;
        flex-direction: column;
    }
    .btn-apps {
        margin: 50px 0;
    }
    .btn-apps ul {
        gap: 10px;
        justify-content: space-between;
    }
    .btn-apps ul li img {
        max-width: initial;
        width: 100%;
    }
    .logo-exa {
        margin-bottom: 50px;
    }
    .controls {
        padding: 80px 0 120px 0;
    }
    .controls .content-center {
        gap: 50px;
    }
    .list-controls {
        gap: 30px;
        grid-template-columns: 1fr;
    }
    .solution {
        background-image: none!important;
    }
    /* .solution .str .img img {
        margin-top: -170px;
    } */
    .steps-solution .steps {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
    }
    .steps-solution .btn-apps {
        margin: 0;
    }
    .steps-solution .btn-apps ul {
        flex-direction: row;
    }
    .steps-solution .steps .list-steps ul {
        grid-template-columns: 1fr;
    }
    .copy .list-copy {
        flex-direction: column-reverse;
    }
}