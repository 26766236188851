.contentLoader {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    justify-content: center;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 30px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100vw;
    z-index: 10;
}
.contentLoader p {
    font-size: 22px;
}
.content_loader {
  align-items: center;
  display: flex;
  height: 160px;
  position: relative;
  justify-content: center;
}
.content_loader > img {
  position: absolute;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}
.rotating_reverse {
  -webkit-animation: rotating 2s linear infinite reverse;
  -moz-animation: rotating 2s linear infinite reverse;
  -ms-animation: rotating 2s linear infinite reverse;
  -o-animation: rotating 2s linear infinite reverse;
  animation: rotating 2s linear infinite reverse;
}