.center {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 700px;
    padding: 32px;
    width: 100vw;
}
@media screen and (max-width: 700px) {
    .center {
        max-width: 100%;
    }
}
.header {
    background-color: var(--color-button);
}
.header h1,
.header span {
    color: white;
    font-size: 22px;
    font-weight: 500;
    line-height: 33.6px;
}
.header span {
    font-size: 20px;
}