.loading {
    background-color: var(--color_neutral-100);
    color: var(--color_neutral-0);
    display: grid;
    height: 100vh;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
    place-items: center;
    top: 0;
    width: 100vw;
    z-index: 11;
}