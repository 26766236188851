.center {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 700px;
    padding: 32px;
    width: 100vw;
}

.frmCheckout {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-areas: 
        'bloco1 bloco2'
        'bloco1 bloco3';
    width: 100%;
    margin-top: 40px;
}
.frmCheckout button {
    background-color: var(--color-button);
    border: none;
    border-radius: 16px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 20px 40px;
    width: 100%;
}
.frmCheckout button:disabled {
    background-color: var(--color-grey-dark);
    opacity: .4;
    cursor: not-allowed;
}
.frmCheckout label {
    font-size: 12px;
    margin-bottom: 10px;
}
.frmCheckout input,
.frmCheckout select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    color: var(--color-grey-dark);
    font-size: 20px;
    outline: none;
    padding-bottom: 6px;
    width: 100%;
}
.blockInput {
    display: grid;
    grid-template-columns: 1fr 20px;
}
.flag {
    border-bottom: 1px solid black;
    padding-bottom: 6px;
}
.flag img {
    max-width: 30px;
    width: 100%;
}
.bloco1 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    grid-area: bloco1;
}
.bloco1 h2 {
    font-size: 24px;
    font-weight: 500;
}
.bloco1 > div {
    display: flex;
    flex-direction: column;
}
.bloco2 {
    display: flex;
    gap: 30px;
    grid-area: bloco2;
    align-items: flex-start;
    flex-direction: column;
}
.bloco2 ul {
    background-color: var(--color-grey);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    width: 100%;
}
.bloco2 ul li {
    align-items: center;
    display: flex;
    font-size: 14px;
    list-style-type: none;
    justify-content: space-between;
}
.bloco2 ul li p {
    font-weight: 500;
}
.bloco2 ul li:not(:first-child) {
    border-top: 1px solid var(--color-grey-dark);
    padding-top: 16px;
}
.bloco3 {
    align-items: flex-start;
    display: none;
    grid-area: bloco3;
}
@media screen and (max-width: 700px) {
    .center {
        max-width: 100%;
    }
    .frmCheckout {
        grid-template-columns: 1fr;
        grid-template-areas: 
            'bloco2'
            'bloco1'
            'bloco3';
    }
    .bloco3 {
        display: flex;
    }
    .bloco2 > button {
        display: none;
    }
    .frmCheckout {
        margin-top: 0;
    }
}