* {
    box-sizing: border-box;
    font-family: SpaceGrotesk;
    font-size: 100%;
    margin: 0;
    padding: 0;
}
html {
    scroll-behavior: smooth;
}
body {
    background-color: var(--color_neutral-100);
    min-height: 100vh;
}
.struct {
    display: grid;
    height: 100vh;
    grid-template-rows: auto 1fr auto;
    background-color: white;
}