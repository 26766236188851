@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";



@layer components {
    @font-face {
        font-family: SpaceGrotesk;
        src: url('../fonts/SpaceGrotesk.ttf')
    }

    @font-face {
        font-family: Nunito;
        src: url('../fonts/Nunito-Regular.ttf');
        font-weight: normal;
    }

    @font-face {
        font-family: Nunito;
        src: url('../fonts/Nunito-Bold.ttf');
        font-weight: bold;
    }
}