:root {
    /* COLORS FOR CHECKOUT APP */
    --color-primary: #123382;
    --color-secondary: #6531BD;
    --color-tertiary: #9e9517;
    --color-grey-dark: #666666;
    --color-grey: #F0F0F0;
    --color-button: #004BC1;
    /* BORDER RADIUS */
    --border-radius_none: 0rem;
    --border-radius_base: 0.25rem;
    --border-radius_x2: calc(var(--border-radius_base) * 2);
    --border-radius_x3: calc(var(--border-radius_base) * 3);
    --border-radius_x4: calc(var(--border-radius_base) * 4);
    --border-radius_x5: calc(var(--border-radius_base) * 5);
    --border-radius_x6: calc(var(--border-radius_base) * 6);
    --border-radius_x7: calc(var(--border-radius_base) * 7);
    --border-radius_x9: calc(var(--border-radius_base) * 9);
    --border-radius_pill: 31.25rem;
    --border-radius_full: 50%;
    /* COLORS */
    --color_primary: hsla(217, 100%, 38%, 1);
    --color_secondary: hsla(77, 100%, 43%, 1);
    --color_gray-light: var(--color_neutral-90);
    /* --color_gray-dark: var(--color_neutral-30); */
    --color_blue: #007DFF;
    --color_blue-light: hsla(220, 52%, 88%, 1);
    --color_typograph: var(--color_neutral-20, hsla(0, 0%, 20%, 1));
    /* => Neutrals */
    --color_neutral-0: hsla(0, 0%, 0%, 1);
    --color_neutral-10: hsla(0, 0%, 10%, 1);
    --color_neutral-20: hsla(0, 0%, 20%, 1);
    --color_neutral-30: hsla(0, 0%, 30%, 1);
    --color_neutral-33: hsla(0, 0%, 33%, 1);
    --color_neutral-40: hsla(0, 0%, 40%, 1);
    --color_neutral-50: hsla(0, 0%, 50%, 1);
    --color_neutral-60: hsla(0, 0%, 60%, 1);
    --color_neutral-70: hsla(0, 0%, 70%, 1);
    --color_neutral-80: hsla(0, 0%, 80%, 1);
    --color_neutral-90: hsla(0, 0%, 90%, 1);
    --color_neutral-100: hsla(0, 0%, 100%, 1);
    /* => System Action */
    --color_error: hsla(357, 100%, 59%, 1);
    --color_success: hsla(140, 63%, 56%, 1);
    --color_information: hsla(204, 98%, 62%, 1);
    --color_warning: hsla(49, 100%, 58%, 1);
    /* FONT FAMILY */
    --font-family_primary: SpaceGrotesk;
    --font-family_secondary: Nunito;
    /* FONT SIZE */
    --font-size_nano: 0.625rem; 
    --font-size_small: 0.75rem;
    --font-size_medium: 0.875rem;
    --font-size_default: 1rem;
    --font-size_large: 1.25rem;
    --font-size_xlarge: 1.5rem;
    --font-size_big: 1.75rem;
    --font-size_xbig: 2rem;
    --font-size_display: 2.5rem;
    /* FONT WEIGHT */
    --font-weight_light: 300;
    --font-weight_regular: 400;
    --font-weight_medium: 500;
    --font-weight_semibold: 600;
    --font-weight_bold: 700;
    /* LETTER SPACING */
    --letter-spacing_none: 0rem;
    --letter-spacing_minor1: -0.01rem;
    --letter-spacing_minor2: -0.02rem;
    --letter-spacing_minor3: -0.03rem;
    --letter-spacing_minor4: -0.04rem;
    --letter-spacing_minor5: -0.05rem;
    --letter-spacing_tiny: 0.01rem;
    --letter-spacing_small: 0.02rem;
    --letter-spacing_medium: 0.03rem;
    --letter-spacing_large: 0.04rem;
    --letter-spacing_big: 0.05rem;
    /* LINE HEIGHT */
    --line-height_default: 100%;
    --line-height_small: 116%;
    --line-height_medium: 124%;
    --line-height_large: 148%;
    --line-height_big: 172%;
    /* OPACITY */
    --opacity_total: 0;
    --opacity_level-1: 0.16;
    --opacity_level-2: 0.32;
    --opacity_level-3: 0.48;
    --opacity_level-4: 0.80;
    --opacity_none: 1;
    scroll-behavior: auto !important;
}
