.center {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 700px;
    padding: 32px;
    width: 100vw;
}
.footer {
    align-items: center;
    background-color: var(--color-button);
    color: var(--color-grey);
    display: flex;
    font-size: 12px;
    gap: 20px;
    justify-content: space-between;
    margin-top: 30px;
    padding: 30px;
}
.footer > div a {
    color: white;
}
@media screen and (max-width: 700px) {
    .center {
        max-width: 100%;
    }
    .footer {
        align-items: flex-start;
        flex-direction: column-reverse;
        justify-content: flex-start;
    }
}