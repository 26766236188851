@keyframes slideUp {
    from {
      bottom: -100%;
      opacity: 0;
    }
    to {
      bottom: 16px;
      opacity: 1;
    }
}
  
.slide-up {
    animation: slideUp 2s ease forwards;
}