/* 
    SPACE GROTESK
    https://fonts.google.com/specimen/Space+Grotesk 
*/
@font-face {
    font-family: SpaceGrotesk;
    src: url('../fonts/SpaceGrotesk.ttf')
}

@font-face {
    font-family: Nunito;
    src: url('../fonts/Nunito-Regular.ttf')
}